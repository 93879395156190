import React, { useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import ReactGA from "react-ga"
import { Helmet } from "react-helmet"

// import app components
import SidebarContent from "components/sidebarContent"
import Textarea from "components/textarea"
import Edges from "components/edges"

import { getUrlPath } from "utils/getUrlPath"

export default function Video(props) {
  const {
    data: {
      wp: {
        themeSettings: {
          siteOptions: { defaultHeroImage }
        }
      },
      wpVideo: {
        title,
        uri,
        date,
        Video: { url, image, description }
      }
    }
  } = props

  const { GATSBY_SITE_URL } = process.env

  const videoId =
    !!url && (url.includes("youtu.") || url.includes("/embed"))
      ? url.substring(url.lastIndexOf("/") + 1)
      : !!url && url.includes("youtube")
      ? url.substring(url.lastIndexOf("=") + 1)
      : null

  const videoObj = {
    "@context": "https://schema.org/",
    "@type": "VideoObject",
    name: title,
    description: description,
    thumbnailUrl: image?.localFile?.publicURL ? GATSBY_SITE_URL + image.localFile.publicURL : "",
    uploadDate: date,
    contentUrl: url
  }

  useEffect(() => {
    const trackingId = "UA-40595314-2"
    ReactGA.initialize(trackingId)
  }, [])

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(videoObj)}</script>
      </Helmet>
      <Container>
        <SidebarContent
          image={defaultHeroImage}
          title={title}
          slug={getUrlPath(uri)}
          hideSidebar={true}
          edgesSize={"md"}
        >
          <Edges size="sm">
            {videoId && (
              <VideoContainer>
                <iframe title={title} src={`https://www.youtube.com/embed/` + videoId + `?autoplay=1&rel=0`} />
              </VideoContainer>
            )}
            {description && <TextContainer content={description} />}
          </Edges>
        </SidebarContent>
      </Container>
    </>
  )
}

const VideoContainer = styled.div`
  width: 100%;

  iframe {
    width: 100%;
    aspect-ratio: 16/9;
  }
`

const TextContainer = styled(Textarea)`
  margin-top: 24px;
`

const Container = styled.div`
  padding-bottom: 30px;
`

export const CollectionQuery = graphql`
  query DefaultSingleVideo($id: String!) {
    wpVideo(id: { eq: $id }) {
      title
      uri
      date
      Video {
        fieldGroupName
        url
        description
        image {
          altText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED, width: 300)
            }
          }
        }
      }
    }
    wp {
      themeSettings {
        siteOptions {
          defaultHeroImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
